<template>
    <div class="page-main">
        <div class="main-banner">
            <img src="../../assets/image/main-page/product_banner.jpg" alt="">
            <div class="banner-text">
                <p class="tit">产品中心</p>
                <p class="tit-en">Product Center</p>
            </div>
        </div>
        <product-module></product-module>
    </div>
</template>

<script>
    import ProductModule from '../page-module/product-module'
    export default {
        name: "product-page",
        components: {
            ProductModule
        },
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/main-module";
</style>
